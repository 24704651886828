import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { ContactCommandDTO } from '../models/contact/commands/ContactCommandDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: "root"
})
export class ContactAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    contact(command: ContactCommandDTO): Observable<void> {
        return this.httpPost(`/contact`, command);
    }
}